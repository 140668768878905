@import url('https://fonts.googleapis.com/css2?family=Bree+Serif&display=swap');

:root {
  --yellow: #ffcf00;
  --green: #4b6964;
}


body {
  margin: 0;
}

.ticker {
  display: block;
  position: absolute;
  background-color: var(--yellow);
  color: var(--green);
  padding: 8px 0 10px;
  width: 100%;
  font-size: 24px;
  font-family: 'Bree Serif', serif;
}

.marquee > div {
  /* width: 30vw; */
  margin: 0 40px;
  text-align: center;
}

.bone {
  height: 20px;
}

.benson {
  height: 30px;
  vertical-align: bottom;
}

.main {
  display: block;
  background: rgb(141,172,167);
  background: radial-gradient(circle, rgba(141,172,167,1) 0%, rgba(75,105,100,1) 74%);
  height: 100vh;
}

.logo {
  text-align: center;
  padding-top: 100px;
}

.logo img {
  width: 40vw;
}

/* TRAY ANIMATION */

.tray-container {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform:translateX(-50%)
}

#tray-img {
  width: 60vw;
}

.center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 250px;
  height: 250px;
  border: 3px solid #73AD21;
  padding: 2px;
}

/*  SOCIAL LINKS */

.social-links {
  position: absolute;
  display: flex;
  justify-content: space-between;
  /* width: 275px; */
  right: 24px;
  top: 75px;
}

.social-link-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 45px;
  height: 45px;
  font-size: 24px;
  border: 2px solid var(--yellow);
  color: var(--yellow);
  border-radius: 50% 50%;
  margin-left: 10px;
  /* cursor: pointer; */
}

.social-link-item:hover {
  background-color: var(--yellow);
  color: var(--green);
}

.social-link-item.disable {
  opacity: .55;
}

.social-link-item.disable:hover {
  background-color: transparent;
  color: var(--yellow);
}

.social-link-item .svg {
  position: relative;
  max-width: 30px;
  padding: 10px 8px 8px;
  filter: invert(73%) sepia(84%) saturate(1479%) hue-rotate(1deg) brightness(107%) contrast(103%);
}

.social-link-item .svg:hover {
  filter: invert(37%) sepia(29%) saturate(356%) hue-rotate(120deg) brightness(94%) contrast(85%);
}

.social-link-item:first-child {
  margin-left: 0px;
}


.sub-text {
  position: absolute;
  font-size: 10px;
  bottom: -15px;
  width: 70px;
  text-align: center;
  font-family: 'Bree Serif', serif;
}

/*  MEDIA QUERIES */

@media screen and (max-width: 700px) {
  .logo img {
    width: 80vw;
  }

  #tray-img {
    width: 80vw;
  }

  .tray-container {
    position: relative;
    text-align: center;
    bottom: 80px;
  }

  .social-links {
    position: relative;
    margin: 0 auto;
    right: 0;
    top: 0;
    width: 125px;
  }
}